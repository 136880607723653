// Gatsby supports TypeScript natively!
import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import TopImage from "../components/topImage"
import { GatsbyImage } from "gatsby-plugin-image"

export default function RapoarteDeActivitate({ data }) {
  const { frontmatter, topImage, featuredImg } = data.markdownRemark
  const pdfs = data.allMarkdownRemark.nodes
  return (
    <Layout>
      <Seo title={frontmatter.title} />
      <TopImage
        topImage={topImage.childImageSharp.gatsbyImageData}
        title={frontmatter.title}
      />
      <div className="container mx-auto flex flex-column w-9/12 pt-5 pb-5 gap-5">
        <hr />
        {pdfs.map(raport => (
          <div key={raport.id}>
            <div className="mx-auto flex flex-row gap-16">
              <div>
                <a href={raport.frontmatter.pdf} target="_blank">
                  <GatsbyImage
                    image={featuredImg.childImageSharp.gatsbyImageData}
                    alt="pdf"
                  />
                </a>
              </div>
              <div className="mt-auto mb-auto">
                <a
                  href={raport.frontmatter.pdf}
                  target="_blank"
                  className="text-xl"
                >
                  {raport.frontmatter.title}
                </a>
              </div>
            </div>
            <hr />
          </div>
        ))}
      </div>
    </Layout>
  )
}

export const rapoarteDeActivitate = graphql`
  query RapoarteDeActivitate {
    markdownRemark(
      fileAbsolutePath: { regex: "/rapoarteDeActivitate/mainPage/" }
    ) {
      htmlAst
      frontmatter {
        title
      }
      topImage {
        childImageSharp {
          gatsbyImageData(
            transformOptions: { cropFocus: SOUTHEAST, fit: CONTAIN }
            placeholder: BLURRED
            layout: FULL_WIDTH
          )
        }
      }
      featuredImg {
        childImageSharp {
          gatsbyImageData(
            transformOptions: { cropFocus: SOUTHEAST, fit: CONTAIN }
            layout: FIXED
            placeholder: BLURRED
            width: 100
            height: 100
          )
        }
      }
    }
    allMarkdownRemark(
      sort: { order: DESC, fields: frontmatter___date }
      filter: { fileAbsolutePath: { regex: "/rapoarteDeActivitate/rapoarte/" } }
    ) {
      nodes {
        id
        frontmatter {
          title
          pdf
        }
      }
    }
  }
`
